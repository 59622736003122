import { LogLevelAll, Logger, LoggerMemoryHandler, getGlobalLogger, logCaptureConsole } from 'zeed'
import type { LoggingInfo } from '@/_types/logging'

const logging: LoggingInfo = {
  messages: [],
}

async function mainSetup() {
  if (!localStorage.getItem('zeed')) {
    getGlobalLogger().registerHandler(LoggerMemoryHandler({
      level: LogLevelAll,
      filter: '*',
      compact: true,
      messages: logging.messages,
    }))

    const log = Logger('console')
    logCaptureConsole(log)

    window.addEventListener('error', (event) => {
      log.error('Catched error', event)
    })

    window.addEventListener('unhandledrejection', (event) => {
      log.error('Catched unhandled rejection', event)
    })

    log.info('capturing mode for console')
  }

  const { main } = await import('./main-delayed')

  void main(logging)
}

void mainSetup()
